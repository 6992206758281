<template>
  <div>
    <banner bold-title="Reserva"
            :title="` de charlas técnicas - ${user.fairName}`"
            type="banner--admin">
    </banner>
    <div class="pt-3 px-7">
      <div class="row">
        <div class="col-12">
          <h4>
            Importante:
            <span style="font-weight: initial !important;">
              Si tienes una charla aprobada y necesitas cancelarla,
              por favor haz la solicitud a los correos logistica@fise.co y fise@fise.co
            </span>
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-end">
          <v-btn
            color="secondary"
            class="text-none elevation-0"
            dark
            @click="onCreate">
            Crear charla
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <data-table ref="conferenceBookings" :params="params" :url="url" :headers="headers">

            <template v-slot:item.status="{ item }">
              <v-chip
                :color="bookingConferenceStatus[item.status].color"
                dark
              >
                {{ bookingConferenceStatus[item.status].description }}
              </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    @click="onEdit(item)"
                    class="ma-2 elevation-0"
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="primary"
                    :disabled="item.status === 'rejected'"
                  >
                    <v-icon
                      center
                      dark
                      small
                    >
                      fas fa-edit
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar charla</span>
              </v-tooltip>
            </template>
          </data-table>
        </div>
      </div>
      <pending-booking v-if="step<12"></pending-booking>
    </div>
    <conference-booking-modal ref="conferenceBookingModal"/>
  </div>
</template>
<script>
import Banner from '../../../components/banner/Banner'
import { CONFERENCE_BOOKINGS_URL } from '@/constants/ServicesConstants'
import crudServiceMixin from '../../../mixins/crudServiceMixin'
import sessionMixin from '../../../mixins/sessionMixin'
import loaderMixin from '../../../mixins/loaderMixin'
import PendingBooking from './components/PendingBooking'
import headers from './data/Headers'
import DataTable from '../../../components/data-table/DataTable'
import BOOKING_CONFERENCE_STATUS from '../../../constants/BookingConferenceStatusConstants'
import ConferenceBookingModal from '@/views/private/conference-bookings/components/ConferenceBookingModal'

export default {
  name: 'ConferenceBookings',
  components: { DataTable, PendingBooking, Banner, ConferenceBookingModal },
  mixins: [crudServiceMixin, sessionMixin, loaderMixin],
  async created () {
    try {
      this.user = JSON.parse(localStorage.getItem('userInfo')).user
      this.showLoader()
    } finally {
      this.hideLoader()
    }
  },
  data () {
    return {
      bookingConferenceStatus: BOOKING_CONFERENCE_STATUS,
      conferenceBooking: null,
      headers,
      params: {
        fairId: this.$route.params.id
      },
      step: 12,
      url: CONFERENCE_BOOKINGS_URL,
      user: null
    }
  },
  methods: {
    onCreate () {
      this.$refs.conferenceBookingModal.open(null, this.params.fairId).then(() => {
        this.$refs.conferenceBookings.getDataFromApi()
      }).catch((error) => {
        throw error
      })
    },
    onEdit (item) {
      this.$refs.conferenceBookingModal.open(item, this.params.fairId).then(() => {
        this.$refs.conferenceBookings.getDataFromApi()
      }).catch((error) => {
        throw error
      })
    }
  }
}
</script>
