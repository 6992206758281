export const BOOKING_CONFERENCE_STATUS = {
  onHold: {
    code: 'onHold',
    description: 'En espera',
    color: 'primary'
  },
  successful: {
    code: 'successful',
    description: 'Aprobado',
    color: 'secondary'
  },
  rejected: {
    code: 'rejected',
    description: 'Rechazada',
    color: 'error'
  }
}
export default BOOKING_CONFERENCE_STATUS
